img {
  max-width: 100%;
  height: 30vh;
  margin: auto;
}

.block__content {
  margin-bottom: 40px;
}

.block__content p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.block__content h2 {
  font-weight: 700;
  font-size: 32px;
  margin: 10px 0 20px;
}

@media (min-width: 768px) {
  img.blog__image {
    height: 600px;
    width: 100%;
  }

  .block__content p {
    font-size: 18px;
  }
}

.MuiCard-root {
  width: 100%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5) !important;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;
}
